import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  ArrayField,
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  Show,
  SimpleShowLayout,
  ArrayInput,
  SimpleFormIterator,
  Edit,
  ValidateForm,
} from "react-admin";

export const TicketsList = () => (
  <List>
    <Datagrid>
      <TextField source="id" sortable={false} />
      <TextField
        source="responses[0].content"
        label="User ticket"
        sortable={false}
      />
      <ShowButton />
    </Datagrid>
  </List>
);

export const TicketsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />

      {/*<DateField label="Cration date" source="created_at.$date" />*/}
      <ArrayField source="responses">
        <Datagrid bulkActionButtons={false}>
          <TextField source="type" />
          <TextField source="content" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

const validateResponse: ValidateForm = (data) => {
  console.log("validateform data:", data);
  const responses = data.document?.responses || [];

  const errors: any = {};

  if (responses.length !== 2) {
    errors.document = {
      responses:
        "There must be exactly one user_ticket response and one assistant response.",
    };

    return Object.keys(errors).length ? errors : undefined;
  }

  if (responses[0].type !== "user_ticket") {
    errors.document = {
      responses: "First type must be user_ticket",
    };
  }

  if (
    responses[1].type !== "assistant" &&
    responses[1].type !== "support_response"
  ) {
    errors.document = {
      responses: "Second type must be assistant or support_response",
    };
  }

  responses.forEach((response: any) => {
    if (!response.content) {
      errors.document = {
        responses: "each response must have the content",
      };
    }
  });

  return Object.keys(errors).length ? errors : undefined;
};

export const TicketCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm validate={validateResponse}>
      <ArrayInput source="document.responses">
        <SimpleFormIterator fullWidth>
          <SelectInput
            source="type"
            choices={[
              { id: "user_ticket", name: "user_ticket" },
              { id: "assistant", name: "assistant" },
              { id: "support_response", name: "support_response" },
            ]}
            validate={[required()]}
            fullWidth
          />
          <TextInput
            fullWidth
            source="content"
            multiline
            validate={[required()]}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

const TicketEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <ArrayInput source="document.responses">
        <SimpleFormIterator>
          <SelectInput
            source="type"
            choices={[
              { id: "user_ticket", name: "User Ticket" },
              { id: "assistant", name: "Assistant" },
            ]}
          />
          <TextInput source="content" multiline />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);
