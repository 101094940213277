import {
  List,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  ArrayField,
} from "react-admin";
import { Show, SimpleShowLayout } from "react-admin";

export const ChatSessionsList = () => (
  <List>
    <Datagrid>
      <TextField source="id" sortable={false} />
      <DateField
        source="created_at.$date"
        label="created_at"
        sortable={false}
      />
      <TextField
        source="messages.length"
        label="Messages count"
        sortable={false}
      />
      <ShowButton />
    </Datagrid>
  </List>
);

export const ChatSessionShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField label="Cration date" source="created_at.$date" />
      <ArrayField
        source="messages"
        sort={{ field: "timestamp.$date", order: "DESC" }}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="role" />
          <TextField source="text" />
          <DateField source="timestamp.$date" label="Timestamp" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
